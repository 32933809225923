// Import the functions you need from the SDKs you need
import { initializeApp,  } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";


const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
};
// Initialize Firebase

export const firebaseApp = initializeApp(firebaseConfig)

/*
const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider(import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

*/

export const auth = getAuth(firebaseApp)
//connectAuthEmulator(auth, "http://127.0.0.1:9099");
export const firestore = getFirestore(firebaseApp)
//connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
export const analytics = getAnalytics(firebaseApp);

export default firebaseApp
