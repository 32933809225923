import { createRouter, createWebHistory } from "vue-router";

import { getCurrentUser } from "vuefire";
import * as Sentry from '@sentry/vue';


const router = createRouter({
  scrollBehavior() {
    const el = window.document.getElementsByTagName('main');
    if( el.length > 0) {
      el[0].scrollTo(0,0)
    }
  },
  history: createWebHistory(),
  routes: [

    {
      name: "home",
      path: "/",
      component: () => import("/src/pages/Home.vue"),
    },
    {
      name: "embedded",
      path: "/embedded",
      component: () => import("/src/layouts/embedded.vue"),
      children: [
        {
          name: "embedded-home",
          path: "",
          component: () => import("/src/pages/Embedded.vue"),
          props: { isEmbedded: true }
        },
        {
          name: "embedded-register",
          path: "register",
          component: () => import("/src/pages/Register.vue"),
          props: { isEmbedded: true }
        },
        {
          name: "embedded-bodygraph",
          path: "bodygraph",
          component: () => import("/src/pages/Dashboard.vue"),
          props: { isEmbedded: true, requiresAuth: true }
        }
      ]
    },
    {
      name: "root",
      path: "/",
      component: () => import("/src/layouts/landing.vue"),
      children: [
        {name: "landing", path: "landing", component: () => import("/src/pages/Landing.vue"),},
        {name: "legal", path: "legal/:type", component: () => import("/src/pages/Legal.vue"),},

      ]
    },
    {
      path: "/user",
      component: () => import("/src/layouts/auth.vue"),
      children: [
        {
          name: "register",
          path: "register",
          component: () => import("/src/pages/Register.vue"),
        },
        {
          name: "register-password-less",
          path: "without-password",
          component: () => import("/src/pages/Anonymously.vue"),
        },
        {
          name: "login",
          path: "login",
          component: () => import("/src/pages/Login.vue"),
        },{
          name: "reset-password",
          path: "reset-password",
          component: () => import("/src/pages/ResetPassword.vue"),
        },{
          name: "set-password",
          path: "set-password",
          component: () => import("/src/pages/SetPassword.vue"),
        }
      ],
    },
    {
      path: "/payment/:session/success",
      component: () => import("/src/pages/PaymentSuccess.vue"),
      meta: { requiresAuth: true },
    },
    {
      name: "reading",
      path: "/reading",
      component: () => import("/src/layouts/mobile.vue"),
      meta: { requiresAuth: true },
      children: [
        {
          name: "bodygraph",
          path: ":module(bodygraph)",
          component: () => import("/src/pages/Bodygraph.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "dashboard",
          path: ":module(dashboard)",
          component: () => import("/src/pages/Dashboard.vue"),
          meta: { requiresAuth: true },
        },        
        {
          name: "list",
          path: "list",
          component: () => import("/src/pages/ReadingList.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "reading-upgrade",
          path: "upgrade",
          component: () => import("/src/pages/ReadingUpgrade.vue"),
          meta: { requiresAuth: true },
        },       
        {
          name: "content",
          path: ":module/:part",
          component: () => import("/src/pages/ReadingContent.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "chapter",
          path: ":module/:part/:chapter",
          component: () => import("/src/pages/ReadingContent.vue"),
          meta: { requiresAuth: true },
        },


      ],
    },
  ],
});

router.beforeEach(async (to, from) => {
  const currentUser = await getCurrentUser();

  if (to.name === 'landing') {
    if(currentUser) {
      return {
        path: "/reading/dashboard",
      }
    }
  } else if (to.meta.requiresAuth) {
    // if the user is not logged in, redirect to the login page
    if (!currentUser) {
      return {
        path: "/user/login",
        query: {
          redirect: to.fullPath,
        },
      }
    } else {
      Sentry.setUser({email: currentUser.email});
    }
  }
});

export { router };
